// https://sweetalert2.github.io/#examples

import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

export interface AlertParams {
  html: string // alert 내용
  confirm?: {
    confirmButtonText?: string
    cb?: () => Promise<void> | void | undefined
  }

  deny?: {
    denyButtonText?: string
    cb?: () => Promise<void> | void | undefined
  }
}

export function useAlert() {
  function error(params: AlertParams): void {
    const { html, confirm } = params

    Swal.fire({
      title: `<span class="title-text">오류</span>`,
      html,
      showConfirmButton: true,
      confirmButtonText: '확인',
      confirmButtonColor: 'var(--va-release-primary)',
      showClass: {
        popup: `
          animate__animated
          animate__fadeIn
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOut
          animate__faster
        `,
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed && confirm?.cb) {
        confirm.cb()
      }
    })
  }

  function confirm(params: AlertParams): void {
    const { html, confirm, deny } = params

    Swal.fire({
      title: `<span class="title-text">알림</span>`,
      html,
      showConfirmButton: true,
      confirmButtonText: confirm?.confirmButtonText || '확인',
      confirmButtonColor: 'var(--va-release-primary)',
      showDenyButton: true,
      denyButtonText: deny?.denyButtonText || '취소',
      denyButtonColor: 'var(--va-deny)',
      showClass: {
        popup: `
          animate__animated
          animate__fadeIn
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOut
          animate__faster
        `,
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed && confirm?.cb) {
        confirm.cb()
      } else if (result.isDenied && deny?.cb) {
        deny.cb()
      }
    })
  }

  return { error, confirm }
}
