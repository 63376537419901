const COLORS = {
  background: '#F8F8F8',
  primary: '#3A57E8',
  textPrimary00: '#232D42',
  textPrimary01: '#8A92A6',
  white: '#FFFFFF',
  white01: '#FAFAFA',
  line: '#E9ECEF',
  deny: '#D9D9D9',
  releasePrimary: '#0B407C',
  gray: '#CACACA',
}

export const LLM_MODEL_COLORS = {
  primary00: '#3A57E8',
  primary01: '#4B53DB',
  primary02: '#4B81DB',
  primary03: '#A14BDB',
  primary04: '#D04BDB',
}

export default {
  presets: {
    light: {
      ...COLORS,

      backgroundPrimary: '#F4F6F8',
      backgroundSecondary: '#FFFFFF',
      backgroundCardPrimary: '#F7F9F9',
      backgroundCardSecondary: '#ECFDE6',
      success: '#228200',
      info: '#158DE3',
      danger: '#E42222',
      warning: '#FFD43A',
    },
    dark: {
      backgroundCardPrimary: '#111827',
      backgroundCardSecondary: '#0f172a',
    },
  },
}
