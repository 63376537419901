/**
 * ## [ 채팅 접근 타입 ]
 *
 * - playground - 플레이그라운드
 * - report - 협의록
 * - jqpr - JQPR
 * - assistant_direct - MMS > 직영
 * - assistnat_all - MMS > 협력사
 *  */
export type ChatAccessType =
  | 'playground'
  | 'report'
  | 'jqpr'
  | 'mmsassistant_direct'
  | 'mmsassistant_all'
  | 'assistant_guide'

/** ## ChatAccessType 배열 */
export const validChatAccessTypes: ChatAccessType[] = [
  'playground',
  'report',
  'jqpr',
  'mmsassistant_direct',
  'mmsassistant_all',
  'assistant_guide',
]

/**
 * ## [ 채팅 Input Action ]
 *
 * - image - 이미지 파일 첨부
 * - audio - 음성 파일 첨부
 * - voice - 음성 녹음
 * - camera - 카메라 촬영
 * */
export type ChatInputActionKey = 'image' | 'audio' | 'voice' | 'camera'

/**
 *
 * ## [ 채팅 Input Action Config ]
 * */
export type ChatInputAction = { key: ChatAccessType } & { [type in ChatInputActionKey]: boolean }
