import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { AssistantTarget } from '@/stores/types/appChat'
import { reportBeforeEach } from '@/router/hook/guard'

// import { TOKEN, UUID } from '@/auth/token'
import { RouterName } from '@/router/enum'
import { ReportQueryItem } from './interface'

// NOTE: 배포 어시스턴트 - 과제
const ReleaseReport = () => import('@/pages/assistant/release/ReleaseReport.vue')

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: RouterName.NoPage },
  },
  {
    path: '/',
    redirect: { name: RouterName.NoPage },
  },
  {
    name: RouterName.ReleaseAssistant,
    path: '/release-assistant',
    component: ReleaseReport, //() => import('@/pages/releaseAssistant/ReleaseAssistantPage.vue'),
    props: (route) =>
      ({
        pCode: route.query.pCode,
      }) as ReportQueryItem,
    beforeEnter: reportBeforeEach,
  },
  {
    name: RouterName.ChatAssistant,
    path: '/assistant-chat/:assistantId/:chatId',
    component: () => import('@/pages/releaseAssistant/ReleaseAssistantPage.vue'),
    beforeEnter: (to, _, next) => {
      const { assistantId, chatId } = to.params

      // NOTE: 만약 assistantId가 AssistantTarget에 없다면 RETURN
      const targets: AssistantTarget[] = ['mmsassistant_all', 'mmsassistant_direct', 'assistant_guide']
      if (!targets.includes(assistantId as AssistantTarget)) next({ name: RouterName.NoPage })

      if (!assistantId || !chatId) next({ name: RouterName.NoPage })

      next()
    },
    props: true,
  },
  {
    name: RouterName.NoPage,
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

// 네비게이션 가드 추가
// router.beforeEach((to, from, next) => {
//   const token = TOKEN.GET()

//   if (to.path === '/auth/login') {
//     token ? next(from.path) : next()
//   } else {
//     token ? next() : next('/auth/login')
//   }

//   // if (to.matched.some((record) => record.meta.requiresAuth)) {
//   //   if (!store_auth.isAuthenticated) {
//   //     next({ name: 'login' })
//   //   } else {
//   //     next()
//   //   }
//   // } else {
//   //   next()
//   // }
// })

export default router
