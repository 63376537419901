{
  "common": {
    "assistant": "어시스턴트",
    "guide": "가이드",
    "download": "다운로드"
  },
  "auth": {
    "agree": "동의합니다",
    "createAccount": "계정 생성",
    "createNewAccount": "새 계정 생성",
    "email": "이메일",
    "login": "로그인",
    "password": "비밀번호",
    "recover_password": "비밀번호 찾기",
    "needAssistant": "어시스턴트가 필요하신가요?",
    "sign_up": "가입하기",
    "have_a_acount": "이미 계정이 있으신가요?",
    "password_rule": "비밀번호는 최소 8자 이상이어야 합니다.",
    "keep_logged_in": "로그인 유지",
    "termsOfUse": "이용약관",
    "send_reset_link": "비밀번호 재설정 링크 보내기",
    "go_back": "뒤로가기",
    "reset_password": "비밀번호 재설정",
    "forgot_password": "비밀번호를 잊으셨나요?",
    "forgot_password_info": "비밀번호를 잊어버리셨다고요? 걱정하지 마세요. 아래에 이메일 주소를 입력하시면, 임시 비밀번호가 포함된 이메일을 보내드릴께요."
  },
  "404": {
    "title": "이 페이지는 낚시를 가버렸습니다.",
    "text": "잘못되었다고 생각되시면 다음 주소로 메시지를 보내주세요: ",
    "back_button": "대시보드로 돌아가기"
  },
  "typography": {
    "primary": "기본 텍스트 스타일",
    "secondary": "보조 텍스트 스타일"
  },
  "dashboard": {
    "versions": "버전",
    "setupRemoteConnections": "원격 연결 설정",
    "currentVisitors": "현재 방문자",
    "navigationLayout": "내비게이션 레이아웃",
    "topBarButton": "상단 바",
    "sideBarButton": "사이드 바",
    "monthly_bot_usage": "월별 어시스턴트 사용량"
  },
  "language": {
    "brazilian_portuguese": "포르투갈어(브라질)",
    "english": "영어",
    "spanish": "스페인어",
    "simplified_chinese": "중국어 간체",
    "persian": "페르시아어"
  },
  "menu": {
    "user": "유저 관리",
    "data-management": "데이터 관리",
    "assistant": "어시스턴트",
    "playground": "플레이그라운드",
    "feedback": "피드백",
    "prompt": "프롬프트",
    "data": "데이터 관리",
    "index": "인덱스",
    "web": "웹",
    "auth": "인증",
    "buttons": "버튼",
    "timelines": "타임라인",
    "dashboard": "대시보드",
    "billing": "청구",
    "login": "로그인",
    "preferences": "계정 설정",
    "payments": "결제",
    "settings": "앱 설정",
    "pricing-plans": "가격 플랜",
    "payment-methods": "결제 수단",
    "signup": "가입",
    "recover-password": "비밀번호 복구",
    "recover-password-email": "비밀번호 복구 이메일",
    "404": "404",
    "faq": "FAQ",
    "users": "사용자",
    "projects": "프로젝트"
  },
  "messages": {
    "all": "모든 메시지 보기",
    "new": "{name}의 새 메시지",
    "mark_as_read": "읽음으로 표시"
  },
  "navbar": {
    "messageUs": "웹 개발 문의:",
    "repository": "GitHub 저장소"
  },
  "notifications": {
    "all": "모든 알림 보기",
    "less": "더 적은 알림 보기",
    "mark_as_read": "읽음으로 표시",
    "sentMessage": "메시지를 보냈습니다",
    "uploadedZip": "{type}(으)로 새 Zip 파일 업로드함",
    "startedTopic": "새 토픽 시작함"
  },
  "user": {
    "language": "언어 변경",
    "logout": "로그아웃",
    "profile": "프로필",
    "settings": "설정",
    "billing": "청구",
    "faq": "FAQ",
    "helpAndSupport": "도움말",
    "projects": "프로젝트",
    "account": "계정",
    "explore": "탐색"
  },
  "treeView": {
    "basic": "기본",
    "icons": "아이콘",
    "selectable": "선택 가능",
    "editable": "수정 가능",
    "advanced": "고급"
  },
  "chat": {
    "title": "채팅",
    "sendButton": "보내기"
  },
  "spacingPlayground": {
    "value": "값",
    "margin": "마진",
    "padding": "패딩"
  },
  "spacing": {
    "title": "간격"
  },
  "cards": {
    "cards": "카드",
    "fixed": "고정",
    "floating": "유동",
    "contentText": "얼룩말의 독특한 줄무늬는 사람들에게 가장 친숙한 동물 중 하나입니다.",
    "contentTextLong": "얼룩말의 독특한 줄무늬는 사람들에게 가장 친숙한 동물 중 하나입니다. 그들은 초원, 사바나, 삼림, 가시덤불, 산, 해안 언덕 등 다양한 서식지에서 발견됩니다. 인위적 요인, 특히 가죽을 위한 사냥과 서식지 파괴가 얼룩말 개체 수에 심각한 영향을 미쳤습니다. 그레비얼룩말과 산얼룩말은 멸종 위기에 처해 있습니다. 평원얼룩말은 훨씬 더 풍부하지만, 한 아종인 쿠아가는 멸종되었습니다.",
    "rowHeight": "행 높이",
    "title": {
      "default": "기본",
      "withControls": "컨트롤 포함",
      "customHeader": "맞춤 헤더",
      "withoutHeader": "헤더 없음",
      "withImage": "이미지 포함",
      "withTitleOnImage": "이미지에 제목 포함",
      "withCustomTitleOnImage": "이미지에 맞춤 제목 포함",
      "withStripe": "줄무늬 포함",
      "withBackground": "배경 포함"
    },
    "button": {
      "main": "메인",
      "cancel": "취소",
      "showMore": "더 보기",
      "readMore": "더 보기"
    },
    "link": {
      "edit": "수정",
      "setAsDefault": "기본값으로 설정",
      "delete": "삭제",
      "traveling": "여행",
      "france": "프랑스",
      "review": "리뷰",
      "feedback": "피드백 남기기",
      "readFull": "전체 기사 읽기",
      "secondaryAction": "보조 작업",
      "action1": "작업 1",
      "action2": "작업 2"
    }
  },
  "colors": {
    "themeColors": "테마 색상",
    "extraColors": "추가 색상",
    "gradients": {
      "basic": {
        "title": "버튼 그라데이션"
      },
      "hovered": {
        "title": "마우스오버 버튼 그라데이션",
        "text": "마우스오버 상태에서 원본 스타일(그라데이션 또는 플랫 색상)에 라이트 15% 적용."
      },
      "pressed": {
        "title": "눌린 버튼 그라데이션",
        "text": "누른 상태에서 원본 스타일(그라데이션 또는 플랫 색상)에 어둡게 15% 적용."
      }
    }
  },
  "tabs": {
    "alignment": "탭 정렬",
    "overflow": "탭 오버플로우",
    "hidden": "슬라이더 숨김 탭",
    "grow": "탭 확장"
  },
  "helpAndSupport": "도움말",
  "aboutVuesticAdmin": "Vuestic Admin 정보",
  "search": {
    "placeholder": "검색..."
  },
  "buttonSelect": {
    "dark": "어둡게",
    "light": "밝게"
  }
}
