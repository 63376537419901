import type { NavigationGuardWithThis } from 'vue-router'
import { RouterName } from '@/router/enum'
import { ChatAccessType, validChatAccessTypes } from '@/pages/assistant/interface'
import { ReportQueryItem } from '@/router/interface'
import { TOKEN, UUID } from '@/auth/token'

/** ## [ 협의록 & JQPR 네비게이션 가드 ] */
const reportBeforeEach: NavigationGuardWithThis<undefined> = (to, _, next) => {
  // NOTE: 진입 라우트의 Query
  const { query } = to

  const reportQuery: ReportQueryItem = {
    // NOTE: Query uuid
    uuid: query.uuid as string,

    // NOTE: Query authToken
    authToken: query.authToken as string,

    // NOTE: Query pCode
    pCode: query.pCode as ChatAccessType,
  }

  // NOTE: uuid, authToken, pCode 셋 중 하나라도 없다면 진입 불가
  if (!reportQuery.uuid || !reportQuery.authToken || !reportQuery.pCode) {
    next({ name: RouterName.Login })
  }

  // NOTE: pCode가 올바른 값이 아니라면 진입 불가
  if (!validChatAccessTypes.includes(reportQuery.pCode)) {
    next({ name: RouterName.Login })
  }

  UUID.SET(reportQuery.uuid)
  TOKEN.SET(reportQuery.authToken)

  next()
}

export { reportBeforeEach }
