const AUTH_TOKEN = 'assistantToken'
const AUTH_UUID = 'uuid'

export const TOKEN = {
  SET: (token: string) => {
    sessionStorage.setItem(AUTH_TOKEN, `Bearer ${token}`)
  },
  REMOVE: () => {
    sessionStorage.removeItem(AUTH_TOKEN)
  },
  GET: () => {
    return sessionStorage.getItem(AUTH_TOKEN)
  },
}

export const UUID = {
  SET: (uuid: string) => {
    sessionStorage.setItem(AUTH_UUID, uuid)
  },
  REMOVE: () => {
    sessionStorage.removeItem(AUTH_UUID)
  },
  GET: () => {
    return sessionStorage.getItem(AUTH_UUID)
  },
}
